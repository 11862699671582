/**
 * <FormValidator />
 */

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Alert from '@mui/material/Alert';
import xss from 'xss';
import { setFormButtonLoadingHasChanged } from '../../components/FormBuilderLibrary/slice';
import StyledWrapper from './style';

import IntlMessages from './language-provider/IntlMessages';

const myxss = new xss.FilterXSS({
	whiteList: {
		u: [],
		br: [],
		b: [],
		i: [],
		ol: ['style'],
		ul: ['style'],
		li: [],
		p: ['style'],
		sub: [],
		sup: [],
		div: ['style'],
		em: [],
		strong: [],
		span: ['style']
	}
});

const FormValidator = ({ errors, formRef }) => {
	const dispatch = useDispatch();

	const scrollToElementWithOffset = (name, offset = 64) => {
		const mainElement =
			document.querySelector('#modal-box') || document.querySelector('#baselayout-box');
		const element = formRef
			? formRef.current.querySelector(`[name="${name}"]`)
			: document.querySelector(`[name="${name}"]`);

		if (mainElement && element) {
			const elementRect = element.getBoundingClientRect();
			const mainRect = mainElement.getBoundingClientRect();

			mainElement.scrollTo({
				top: elementRect.y - mainRect.y - offset,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		if (errors?.length > 0) {
			dispatch(setFormButtonLoadingHasChanged(false));
		}
	}, [errors]);
	return (
		<div>
			{errors?.length > 0 && (
				<Alert style={{ marginBottom: '30px' }} severity="error">
					<StyledWrapper.ValidatorTitle>This form contains errors.</StyledWrapper.ValidatorTitle>
					<br />
					Please review the following fields:
					<StyledWrapper.ErrorList>
						{errors.map((error, idx) => (
							<li key={idx}>
								<StyledWrapper.ErrorLabel
									onClick={() => {
										scrollToElementWithOffset(error.field_name);
									}}
								>
									{error.label}
								</StyledWrapper.ErrorLabel>{' '}
								- {error.error}
							</li>
						))}
					</StyledWrapper.ErrorList>
				</Alert>
			)}
		</div>
	);
};

export default FormValidator;
