import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as math from 'mathjs';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import UndoIcon from '@mui/icons-material/Undo';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { getElementIcon } from '../utils';

import StyledWrapper from './style';

const operators = ['(', ')', '+', '-', '/', '*'];

export const MUICalculator = React.forwardRef((props, ref) => {
	const { isFormBuilder } = props;
	const reduxFormAnswers = useSelector((state) => state?.form?.form);
	const [formula] = useState(props?.data?.calc_formula?.BE);
	const [allAnswers, setAllAnswers] = useState([]);
	const [stringFormula, setStringFormula] = useState('');

	const getAllAnswers = () => {
		let answers = [];
		reduxFormAnswers?.forEach((e) => {
			answers = e ? [...answers, ...e] : answers;
		});
		setAllAnswers(answers);
	};

	const getValue = (field_name) => {
		let answer = allAnswers?.find((e) => e?.name === field_name);
		return answer?.value !== '' ? answer?.value : undefined;
	};

	useEffect(() => {
		getAllAnswers();
	}, [reduxFormAnswers]);

	useEffect(() => {
		let createStringFormula = '';
		formula?.map((e) => {
			createStringFormula += operators.includes(e) ? e : getValue(e?.replace(',', ''));
		});
		setStringFormula(createStringFormula);
	}, [allAnswers]);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	const checkValidity = () => {
		try {
			const validFormula = math.evaluate(stringFormula.replaceAll(',', ''))?.toLocaleString();
			return validFormula;
		} catch (error) {
			return ' ';
		}
	};

	return isFormBuilder ? (
		<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
			<StyledWrapper.CustomLabel>
				<>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</>
			</StyledWrapper.CustomLabel>
			<TextField
				error={props?.data?.error}
				name={name}
				inputRef={ref}
				disabled={true}
				style={{ width: '100%' }}
				multiline
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
			/>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={props?.data?.error}>
					{props?.data?.error
						? props?.data?.error?.error
						: parsedHelperText?.[0]
						? parsedHelperText
						: props?.data?.help_message}
				</FormHelperText>
			</StyledWrapper.FormHelperTextContainer>
		</StyledWrapper.FormControlNoBorder>
	) : (
		<div ref={ref}>
			<StyledWrapper.BorderOutline disabled={props?.disabled} id={`${props?.data?.label}`}>
				{' '}
				<p style={{ margin: '0px' }}>
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{!stringFormula.includes('undefined') && checkValidity()}
				</p>
			</StyledWrapper.BorderOutline>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={props?.data?.error}>
					{props?.data?.error
						? props?.data?.error?.error
						: parsedHelperText?.[0]
						? parsedHelperText
						: props?.data?.help_message}
				</FormHelperText>
			</StyledWrapper.FormHelperTextContainer>
		</div>
	);
});

export const CalculatorLogic = (props) => {
	const [fields, setFields] = useState([]);
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const [formula, setFormula] = useState({
		FE: [],
		BE: []
	});
	const [stringFormula, setStringFormula] = useState('');

	useEffect(() => {
		let allFields = [];
		reduxFormSchema.forEach((e) => {
			allFields.push(...e);
		});
		const onlyNumFields = allFields.filter((e) => e?.key === 'MUI_NumberInput');

		setFields(onlyNumFields);
	}, []);

	const handleTextAreaChange = (event) => {
		setFormula(event.target.value);
	};

	const saveFormula = (updatedFormula = formula) => {
		props.update(updatedFormula);
	};

	const showCurrentFormula = () => {
		const reduxFormula = props?.element?.calc_formula;
		return (
			reduxFormula?.FE && (
				<StyledWrapper.CurrentDependanciesContainer>
					<label data-testid={'calculatorLogic-label'}>
						<b>Current Formula:</b>
					</label>
					<StyledWrapper.CurrentDependancies>
						<StyledWrapper.dependancy>{getReadableFormula(reduxFormula)}</StyledWrapper.dependancy>
						<IconButton
							data-testid={'calculatorLogic-delete'}
							aria-label="delete"
							size="small"
							color="error"
							onClick={() => saveFormula(null)}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					</StyledWrapper.CurrentDependancies>
				</StyledWrapper.CurrentDependanciesContainer>
			)
		);
	};

	const findLabel = (fieldName) => {
		const fieldFound = fields?.find((e) => e?.field_name === fieldName);
		return fieldFound.label;
	};

	const getReadableFormula = (reduxFormula = formula) => {
		let readableFormula = '';
		reduxFormula?.FE?.forEach((e) => {
			readableFormula += !operators.includes(e) || e === '(' || e === ')' ? e : ` ${e} `;
		});
		return readableFormula;
	};

	const checkLastEntry = () => {
		const lastEntry = formula?.BE?.[formula?.BE?.length - 1];
		if (lastEntry?.includes('mui_number')) {
			return 'isField';
		}
	};

	useEffect(() => {
		setStringFormula(getReadableFormula());
	}, [formula]);

	return (
		<>
			<StyledWrapper.LogicContainer>
				{checkLastEntry() !== 'isField' ? (
					<StyledWrapper.EditSelect variant="outlined" fullWidth>
						<InputLabel
							variant="outlined"
							htmlFor="formulaFieldSelect"
							data-testid={'calculatorLogic-field-label'}
						>
							Select a field
						</InputLabel>
						<NativeSelect
							inputProps={{
								name: 'numberFields',
								id: 'formulaFieldSelect'
							}}
							data-testid={'calculatorLogic-field-select'}
							onChange={(data) => {
								setFormula({
									FE: [...formula.FE, findLabel(data?.target?.value)],
									BE: [...formula.BE, data?.target?.value]
								});
							}}
						>
							<option value hidden>
								unset
							</option>
							{fields.map((item, index) => {
								if (item.label) {
									return (
										<option
											key={`numberFields-${index}`}
											data-testid={'calculatorLogic-field-option'}
											value={item.field_name}
										>
											{item.label}
										</option>
									);
								}
							})}
						</NativeSelect>
					</StyledWrapper.EditSelect>
				) : (
					<StyledWrapper.EditSelect variant="outlined" fullWidth>
						<InputLabel
							variant="outlined"
							htmlFor="operatorDropdown"
							data-testid={'calculatorLogic-operator-label'}
						>
							Select an Operator
						</InputLabel>
						<NativeSelect
							data-testid={'calculatorLogic-operator-select'}
							inputProps={{
								name: 'operatorDropdown',
								id: 'operatorDropdown'
							}}
							onChange={(data) => {
								setFormula({
									FE: [...formula.FE, data?.target?.value],
									BE: [...formula.BE, data?.target?.value]
								});
							}}
						>
							<option value hidden>
								unset
							</option>
							{operators.map((item, index) => {
								return (
									<option
										key={`operatorDropdown-items-${index}`}
										data-testid={'calculatorLogic-operator-option'}
										value={item}
									>
										{item}
									</option>
								);
							})}
						</NativeSelect>
					</StyledWrapper.EditSelect>
				)}

				<StyledWrapper.ButtonsContainer>
					<Tooltip title="Undo" placement="bottom">
						<IconButton
							data-testid={'calculatorLogic-undo'}
							aria-label="removeLastFormula"
							size="small"
							color="error"
							onClick={() => {
								setFormula({
									FE: formula.FE.slice(0, -1),
									BE: formula.BE.slice(0, -1)
								});
							}}
						>
							<UndoIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</StyledWrapper.ButtonsContainer>
			</StyledWrapper.LogicContainer>
			<FormHelperText style={{ marginTop: '-10px' }}>
				{'Pick from available form fields to build your formula. Only Number fields available.'}
			</FormHelperText>
			<StyledWrapper.TextAreaContainer>
				<StyledWrapper.SaveFormulaBox>{stringFormula}</StyledWrapper.SaveFormulaBox>

				{/* <StyledWrapper.EditTextField
					defaultValue={stringFormula}
					value={stringFormula}
					onChange={handleTextAreaChange}
					data-testid={'calculatorLogic-textarea'}
					id="formulaTextBox"
					disabled={true}
					name={`finalFormula-${props.field_name}`}
					label="Formula"
					variant="outlined"
					sx={{ width: '100%' }}
				/> */}

				<StyledWrapper.ButtonsContainer>
					<Tooltip title="Add Formula" placement="bottom">
						<IconButton
							data-testid={'calculatorLogic-addFormula'}
							aria-label="addFormula"
							size="small"
							color="success"
							onClick={() => formula?.FE?.length > 0 && saveFormula()}
						>
							<DoneIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</StyledWrapper.ButtonsContainer>
			</StyledWrapper.TextAreaContainer>
			{showCurrentFormula()}
		</>
	);
};

MUICalculator.displayName = 'MUI_Calculator';
Registry.register('MUI_Calculator', MUICalculator);
