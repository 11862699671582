import React, { useState, useEffect, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';

import StyledWrapper from './style';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export const MUIStatePicker = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const actualValue = props.data?.options?.find((e) => e?.key === defaultValue);
	const [hasError, setHasError] = useState(props.data?.error || false);

	const [value, setValue] = useState();
	const parsedLabel = useMemo(() => stringToHTML(props.data?.label), [props.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props.data?.help_message),
		[props.data?.help_message]
	);

	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };

	const handleChange = (event) => {
		setValue(event.target.value);
		setHasError(false);
	};

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	return (
		<>
			<FormControl fullWidth variant="outlined">
				<StyledWrapper.CustomLabel
					error={hasError}
					value={parsedLabel?.[0] ? parsedLabel : props.data?.label}
					disabled={disabled ? true : false}
					required={props.data?.required}
					shrink={isFormBuilder || undefined}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{props.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<Select
					sx={styleForNotFormBuilder}
					error={hasError}
					inputProps={{ ref: ref, actualValue: actualValue?.value }}
					id={`StatePickerID_${props.field_name}`}
					disabled={disabled}
					defaultValue={defaultValue}
					value={value}
					name={name}
					onChange={handleChange}
				>
					{props.data?.options?.map((e) => {
						return (
							<MenuItem key={e?.key} value={e?.value}>
								{e?.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

export const STATES = [
	{ value: 'AK', text: 'Alaska', key: 'statePicker_option_1' },
	{ value: 'TX', text: 'Texas', key: 'statePicker_option_2' },
	{ value: 'AL', text: 'Alabama', key: 'statePicker_option_3' },
	{ value: 'AR', text: 'Arkansas', key: 'statePicker_option_4' },
	{ value: 'AZ', text: 'Arizona', key: 'statePicker_option_5' },
	{ value: 'CA', text: 'California', key: 'statePicker_option_6' },
	{ value: 'CO', text: 'Colorado', key: 'statePicker_option_7' },
	{ value: 'CT', text: 'Connecticut', key: 'statePicker_option_8' },
	{ value: 'DC', text: 'District of Columbia', key: 'statePicker_option_9' },
	{ value: 'DE', text: 'Delaware', key: 'statePicker_option_10' },
	{ value: 'FL', text: 'Florida', key: 'statePicker_option_11' },
	{ value: 'GA', text: 'Georgia', key: 'statePicker_option_12' },
	{ value: 'HI', text: 'Hawaii', key: 'statePicker_option_13' },
	{ value: 'IA', text: 'Iowa', key: 'statePicker_option_14' },
	{ value: 'ID', text: 'Idaho', key: 'statePicker_option_15' },
	{ value: 'IL', text: 'Illinois', key: 'statePicker_option_16' },
	{ value: 'IN', text: 'Indiana', key: 'statePicker_option_17' },
	{ value: 'KS', text: 'Kansas', key: 'statePicker_option_18' },
	{ value: 'KY', text: 'Kentucky', key: 'statePicker_option_19' },
	{ value: 'LA', text: 'Louisiana', key: 'statePicker_option_20' },
	{ value: 'MA', text: 'Massachusetts', key: 'statePicker_option_21' },
	{ value: 'MD', text: 'Maryland', key: 'statePicker_option_22' },
	{ value: 'ME', text: 'Maine', key: 'statePicker_option_23' },
	{ value: 'MI', text: 'Michigan', key: 'statePicker_option_24' },
	{ value: 'MN', text: 'Minnesota', key: 'statePicker_option_25' },
	{ value: 'MO', text: 'Missouri', key: 'statePicker_option_26' },
	{ value: 'MS', text: 'Mississippi', key: 'statePicker_option_27' },
	{ value: 'MT', text: 'Montana', key: 'statePicker_option_28' },
	{ value: 'NC', text: 'North Carolina', key: 'statePicker_option_29' },
	{ value: 'ND', text: 'North Dakota', key: 'statePicker_option_30' },
	{ value: 'NE', text: 'Nebraska', key: 'statePicker_option_31' },
	{ value: 'NH', text: 'New Hampshire', key: 'statePicker_option_32' },
	{ value: 'NJ', text: 'New Jersey', key: 'statePicker_option_33' },
	{ value: 'NM', text: 'New Mexico', key: 'statePicker_option_34' },
	{ value: 'NV', text: 'Nevada', key: 'statePicker_option_35' },
	{ value: 'NY', text: 'New York', key: 'statePicker_option_36' },
	{ value: 'OH', text: 'Ohio', key: 'statePicker_option_37' },
	{ value: 'OK', text: 'Oklahoma', key: 'statePicker_option_38' },
	{ value: 'OR', text: 'Oregon', key: 'statePicker_option_39' },
	{ value: 'PA', text: 'Pennsylvania', key: 'statePicker_option_40' },
	{ value: 'RI', text: 'Rhode Island', key: 'statePicker_option_41' },
	{ value: 'SC', text: 'South Carolina', key: 'statePicker_option_42' },
	{ value: 'SD', text: 'South Dakota', key: 'statePicker_option_43' },
	{ value: 'TN', text: 'Tennessee', key: 'statePicker_option_44' },
	{ value: 'TX', text: 'Texas', key: 'statePicker_option_45' },
	{ value: 'UT', text: 'Utah', key: 'statePicker_option_46' },
	{ value: 'VA', text: 'Virginia', key: 'statePicker_option_47' },
	{ value: 'VT', text: 'Vermont', key: 'statePicker_option_48' },
	{ value: 'WA', text: 'Washington', key: 'statePicker_option_49' },
	{ value: 'WI', text: 'Wisconsin', key: 'statePicker_option_50' },
	{ value: 'WV', text: 'West Virginia', key: 'statePicker_option_51' },
	{ value: 'WY', text: 'Wyoming', key: 'statePicker_option_52' }
];

MUIStatePicker.displayName = 'StatePicker';
Registry.register('StatePicker', MUIStatePicker);
