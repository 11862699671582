import React, { useState, useEffect, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormHelperText from '@mui/material/FormHelperText';
import Rating from '@mui/material/Rating';

export const MUIRating = React.forwardRef((props, ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;
	const [value, setValue] = useState(defaultValue);

	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	return (
		<>
			<div className="inner-form-group addBorder" name={name}>
				<StyledWrapper.CustomLabel
					error={hasError}
					value={defaultValue}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel minus6MarginTop'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					error={hasError}
					className={'customInputOutline paddingTop30'}
					disabled={disabled ? true : false}
				>
					<Rating
						error={hasError}
						required={props?.data?.required}
						ref={ref}
						onChange={(e, newValue) => {
							setValue(newValue);
							setHasError(false);
						}}
						defaultValue={value}
						value={value}
						size="large"
						disabled={disabled}
						data-value={value}
					/>
				</StyledWrapper.BorderOutline>
			</div>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUIRating.displayName = 'MUI_Rating';
Registry.register('MUI_Rating', MUIRating);
