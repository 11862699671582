import styled from 'styled-components';
import { getOldBlueColor } from '../../utils/utilFunctions';

const ValidatorTitle = styled.span`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
`;

const ErrorLabel = styled.span`
	color: ${getOldBlueColor()};
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const ErrorList = styled.ul`
	margin: 0;
	padding: 0;
	list-style-type: decimal;
	list-style-position: inside;
`;

export default {
	ValidatorTitle,
	ErrorLabel,
	ErrorList
};
