import React, { useState, useEffect, useMemo } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';
import StyledWrapper from './style';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';

export const MUICheckbox = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;

	const [hasError, setHasError] = useState(props.data?.error || false);
	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	const classNameForNotFormBuilder = !isFormBuilder
		? 'customLabel minus4MarginTop'
		: 'customLabel minus6MarginTop';
	const handleChange = () => {
		setHasError(false);
	};
	return (
		<>
			<div className="inner-form-group addBorder" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={classNameForNotFormBuilder}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				{isFormBuilder ? (
					<StyledWrapper.BorderOutline
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<FormGroup>
							<div
								style={{
									display: 'flex',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const checkboxProps = {};
									checkboxProps.name = `option_${option.key}`;

									checkboxProps.type = 'checkbox';
									checkboxProps.value = option.key;

									checkboxProps.defaultChecked =
										props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;

									return (
										<div key="Checkbox">
											<FormControlLabel
												error={hasError}
												className="disableWhiteBackground noMarginTop"
												disabled={props?.disabled}
												onChange={handleChange}
												control={
													<Checkbox
														id={`fid_${this_key}`}
														{...checkboxProps}
														disabled={props?.disabled}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</FormGroup>
					</StyledWrapper.BorderOutline>
				) : (
					<StyledWrapper.BorderOutlineMarginTop
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<FormGroup>
							<div
								style={{
									display: 'flex',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const checkboxProps = {};
									checkboxProps.name = `option_${option.key}`;

									checkboxProps.type = 'checkbox';
									checkboxProps.value = option.key;

									checkboxProps.defaultChecked =
										props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;

									return (
										<div key="Checkbox">
											<FormControlLabel
												error={hasError}
												className="disableWhiteBackground noMarginTop"
												disabled={props?.disabled}
												onChange={handleChange}
												control={
													<Checkbox
														id={`fid_${this_key}`}
														{...checkboxProps}
														disabled={props?.disabled}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</FormGroup>
					</StyledWrapper.BorderOutlineMarginTop>
				)}
			</div>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUICheckbox.displayName = 'MUI_Checkbox';
Registry.register('MUI_Checkbox', MUICheckbox);
