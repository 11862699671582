import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const FormTemplate = ({ formData, rows }) => {
	const styles = StyleSheet.create({
		column: {
			flexDirection: 'column'
		},
		headerSection: {
			marginTop: 10,
			marginLeft: 10,
			marginRight: 10,
			paddingTop: 10,
			paddingLeft: 10,
			paddingRight: 10
		},
		bodySection: {
			margin: 10,
			padding: 10
		},
		mb5: {
			marginBottom: '5%'
		},
		red: {
			color: 'red'
		},
		bodyHeaderRow: {
			marginTop: '5%',
			marginBottom: '5%',
			paddingTop: 5,
			paddingBottom: 5,
			borderTop: '1px',
			borderTopColor: 'black',
			borderBottom: '1px',
			borderBottomColor: 'black'
		},
		bodyFieldRow: {
			flexDirection: 'row'
		},
		textContent: {
			fontSize: 15
		},
		bold: {
			fontFamily: 'Helvetica-Bold',
			fontWeight: 600
		}
	});

	return (
		<Document>
			<Page size="A4">
				<View style={[styles.headerSection, styles.column]}>
					<Text style={styles.mb5}>
						Program: {formData.programTitle} {`[p-${formData.programID}]`}
					</Text>
					<Text style={styles.mb5}>
						{formData.label} {formData.applicationID && `[api-${formData.applicationID}]`}
					</Text>
					<Text>Instructions:</Text>
					<Text style={styles.textContent}>{formData.instructions}</Text>
				</View>
				<View style={[styles.bodySection, styles.column]}>
					{rows.map((row, index) =>
						row.isSectionLabel ? (
							<View style={styles.bodyHeaderRow} key={index}>
								<Text>{row.label}</Text>
							</View>
						) : (
							<View style={styles.bodyFieldRow} key={index}>
								<Text style={[styles.textContent, styles.mb5]}>
									<Text style={styles.bold}>{row.label}</Text>
									{row.required && <Text style={[styles.red, styles.textContent]}>*</Text>}:{' '}
									{row.value}
								</Text>
							</View>
						)
					)}
				</View>
			</Page>
		</Document>
	);
};

export default FormTemplate;
