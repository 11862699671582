import React, { useState, useEffect, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { getElementIcon } from '../utils';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
export const MUIDatePicker = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, onChange, required, isFormBuilder } = props;
	const [value, setValue] = useState(defaultValue || null);
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const startEndDateError = props?.data.startEndDate && props?.data.error?.error !== 'is required';
	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	const handleChange = (newValue) => {
		setValue(newValue);
		setHasError(false);

		if (onChange) {
			onChange(newValue);
		}
	};
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl sx={{ width: '100%' }} variant="outlined">
				{props?.data?.showTimeSelect ? (
					props?.data?.showTimeSelectOnly ? (
						<>
							<StyledWrapper.CustomLabel disabled={disabled}>
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							</StyledWrapper.CustomLabel>
							<StyledWrapper.CustomTimePicker
								name={name}
								value={value}
								disabled={disabled}
								onChange={(newValue) => handleChange(newValue)}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={hasError}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										style={{ marginTop: '16px' }}
										data-testid={`datepicker-textfield`}
										{...params}
									/>
								)}
							/>
							<StyledWrapper.FormHelperTextContainer>
								<FormHelperText error={hasError}>
									{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
								</FormHelperText>

								{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
							</StyledWrapper.FormHelperTextContainer>
						</>
					) : (
						<>
							<StyledWrapper.CustomLabel disabled={disabled}>
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							</StyledWrapper.CustomLabel>
							<StyledWrapper.CustomDateTimePicker
								name={name}
								minDate={props?.data?.min_date || props?.data?.min_value}
								maxDate={props?.data?.max_date || props?.data?.max_value}
								disabled={disabled}
								value={value}
								onChange={(newValue) => handleChange(newValue)}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={hasError}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										data-testid={`datepicker-textfield`}
										style={{ width: '100%', marginTop: '16px' }}
										{...params}
									/>
								)}
							/>
							<StyledWrapper.FormHelperTextContainer>
								<FormHelperText error={hasError}>
									{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}{' '}
								</FormHelperText>
								{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
							</StyledWrapper.FormHelperTextContainer>
						</>
					)
				) : (
					<>
						<StyledWrapper.CustomLabel disabled={disabled}>
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
								{(required || props?.data?.required) && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</>
						</StyledWrapper.CustomLabel>
						<StyledWrapper.CustomDatePicker
							minDate={props?.data?.min_date || props?.data?.min_value}
							maxDate={props?.data?.max_date || props?.data?.max_value}
							value={value}
							disabled={disabled}
							error={hasError}
							className={isFormBuilder ? 'removeMarginTop noBorder' : ''}
							inputRef={ref}
							onChange={(newValue) => handleChange(newValue)}
							renderInput={(params) => (
								<TextField
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
									required={required}
									name={name}
									data-testid={`datepicker-textfield`}
									style={{ width: '100%', marginTop: '16px' }}
									{...params}
									error={hasError}
								/>
							)}
						/>
					</>
				)}
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{props.data.error?.error &&
						(startEndDateError
							? `${props.data.error.error}`
							: props?.data.startEndDate
							? `Start/End date is required`
							: `${props.data.label} ${props.data.error.error}`)}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</LocalizationProvider>
	);
});

MUIDatePicker.displayName = 'MUI_DatePicker';
Registry.register('MUI_DatePicker', MUIDatePicker);
