import React, { useState, useEffect, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import FormHelperText from '@mui/material/FormHelperText';
import { stringToHTML } from '../utils';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getElementIcon } from '../utils';
import StyledWrapper from './style';

export const MyInput = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, data, isFormBuilder } = props;
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	const handleChange = () => {
		setHasError(false);
	};
	const isCoreInfoApplication = data?.custom_options?.includes('core-application');

	return (
		<>
			{!disabled && !data?.custom_options?.includes('completely-disabled') ? (
				<>
					<FormControl sx={{ width: '100%' }} variant="outlined" name={name}>
						<StyledWrapper.CustomLabel
							value={defaultValue}
							disabled={disabled}
							required={props?.data?.required}
							htmlFor="outlined-error-helper-text"
						>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
							{isFormBuilder && (
								<div>
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</div>
							)}
						</StyledWrapper.CustomLabel>
						<StyledWrapper.CustomInput
							error={hasError}
							id="outlined-error-helper-text"
							required={props?.data?.required}
							inputRef={ref}
							disabled={disabled}
							defaultValue={defaultValue}
							style={{ width: '100%', minHeight: '56px' }}
							onChange={handleChange}
							className={'noMarginPlease'}
							multiline
						/>
					</FormControl>
					<StyledWrapper.FormHelperTextContainer>
						<FormHelperText error={hasError}>
							{' '}
							{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
						</FormHelperText>
						{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
					</StyledWrapper.FormHelperTextContainer>
				</>
			) : (
				<>
					<FormControl sx={{ width: '100%' }} variant="outlined" name={name}>
						<StyledWrapper.CustomLabel
							value={defaultValue}
							disabled={
								disabled == 'disabled' || data?.custom_options?.includes('completely-disabled')
							}
							required={props?.data?.required}
							htmlFor="outlined-adornment-password"
						>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
							{isFormBuilder && (
								<div>
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</div>
							)}
						</StyledWrapper.CustomLabel>
						<StyledWrapper.CustomInput
							error={hasError}
							id="outlined-adornment-password"
							name={name}
							inputRef={ref}
							disabled={disabled || data?.custom_options?.includes('completely-disabled')}
							defaultValue={defaultValue}
							onChange={handleChange}
							value={defaultValue}
							style={{ width: '100%', minHeight: '56px' }}
							multiline
							type={'text'}
							inputComponent={() => {
								return (
									<div style={{ display: 'flex', gap: '10px' }}>
										{(props?.data?.chip || isCoreInfoApplication) && (
											<Chip
												id={'programDetails-statusChip'}
												data-testid={`programDetails-statusChip`}
												label={props?.data?.chip || 'Status'}
												sx={{
													backgroundColor: getChipColor(props?.data?.chip || 'Status'),
													color: getChipTextColor(props?.data?.chip),
													WebkitTextFillColor:
														props?.data?.chip === 'Unassigned' ? 'black' : 'white!important'
												}}
												size="small"
											/>
										)}
										<p
											style={{
												width: data?.isLink ? data?.customWidth || '48%' : '100%',
												textOverflow: data?.isLink ? 'ellipsis' : 'none',
												overflow: 'hidden',
												whiteSpace: data?.isLink ? 'nowrap' : 'auto',
												margin: '0px'
											}}
										>
											{defaultValue ? defaultValue : isCoreInfoApplication && '[..]'}
										</p>

										{data?.isLink && (
											<IconButton
												onClick={() => {
													navigator.clipboard.writeText(defaultValue);
												}}
												aria-label="fingerprint"
												color="inherit"
												style={{ padding: '0px' }}
											>
												<ContentCopyIcon />
											</IconButton>
										)}
									</div>
								);
							}}
						/>
					</FormControl>
					<StyledWrapper.FormHelperTextContainer>
						<FormHelperText error={hasError}>
							{' '}
							{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
						</FormHelperText>
						{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
					</StyledWrapper.FormHelperTextContainer>
				</>
			)}
		</>
	);
});

MyInput.displayName = 'MUI_Input';
Registry.register('MUI_Input', MyInput);
